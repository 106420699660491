export const partners = [
  {
    src: '/assets/img/JCK.png',
    href: 'https://jck.nl/',
    title: 'Joods Cultureel Kwartier',
  },
  {
    src: '/assets/img/cultura.png',
    href: 'http://www.culturaitalia.it/',
    title: 'Cultura Italia',
  },
  {
    src: '/assets/img/polin.png',
    href: 'https://www.polin.pl/en',
    title: 'Museum of the History of Polish Jews',
  },
  {
    src: '/assets/img/pangeanic_logo.png',
    href: 'https://www.pangeanic.com/',
    title: 'Pangeanic',
  },
]
