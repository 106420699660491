import { send, dropMessages } from 'rasa-webchat'

const widgetProps = {
  embedded: true,
  storage: 'local',
  initPayload: '/get_started',
  interval: 100, // ms between each message
  customData: {}, // arbitrary custom data. Stay minimal as this will be added to the socket
  socketUrl: 'https://europeana-bot.jhn.ngo',
  //socketUrl: "http://localhost:16200",
  socketPath: '/socket.io/',
  title: 'Europeana',
  onSocketEvent: {
    bot_uttered: (message) => {
      console.log('Bot sent message: ' + JSON.stringify(message))
      if (message.text === 'RESTART') {
        send(
          `/set_language{"language" : "${localStorage.getItem('chat_lang')}"}`
        )

        setTimeout(() => {
          dropMessages()
        }, 2000)
      }
    },
  },
  inputTextFieldHint: 'Type a message...',
  connectingText: 'Waiting for server...',
  hideWhenNotConnected: false,
  fullScreenMode: false,
  profileAvatar:
    'https://europeana-pro-bot.jhn.ngo/demo/europeana_pro/europeana-logo-white-avatar.png',
}

export default widgetProps
