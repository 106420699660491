import { Widget } from 'rasa-webchat'
import React from 'react'
import './App.scss'
import LangSwitcher from './LangSwitcher'
import { partners } from './partners'
import widgetProps from './widgetProps'

const App = () => {
  return (
    <div className="app">
      <header className="header">
        <div className="header-box">
          <div className="header__logo header__logo_bot"></div>
          <div className="header__logo header__logo_europeana"></div>
          <h1 className="header__headline">
            Multilingual
            <br /> Europeana Chatbot
          </h1>
        </div>
      </header>
      <div className="mockup">
        <LangSwitcher />
        <img src="/assets/img/culturebot-mockup.png" alt="Culturebot" />
        <div className="widget">
          <Widget {...widgetProps} />
        </div>
      </div>
      <main className="chatbot">
        <div className="chatbot-box">
          <h2 className="chatbot__description">
            AI-based multilingual chatbot for Europeana and its content network
            – cultural heritage institutions and aggregators – to guide online
            visitors through their collections.
          </h2>
          <div className="chatbot-info">
            <section className="chatbot-info-card">
              <h3 className="chatbot-info-card__title">About the Project</h3>
              <p className="chatbot-info-card__details">
                Culture Chatbot is a Europeana Generic Service funded under CEF
                Telecom programme of the European Union. The project explores
                the potential of conversational interfaces (UI) to engage users
                with cultural heritage content provided by Europeana and its
                network. On top of the platform delivered by the project (based
                on{' '}
                <a
                  href="http://rasa.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rasa
                </a>
                ) it is possible to develop multilingual chatbots for a variety
                of use-cases and that run of different platforms – websites,
                social media and messaging tools. For other examples, see{' '}
                <a
                  href="https://rasa.com/showcase/jhn-ams/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chatbot for Jewish Historical Museum in Amsterdam
                </a>
                ,{' '}
                <a
                  href="https://www.polin.pl/en/culture-chatbot"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chatbot for POLIN
                </a>{' '}
                and{' '}
                <a
                  href="http://www.culturaitalia.it/opencms/index.jsp?language=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Assisted Search for the CulturaItalia Portal
                </a>
                .
              </p>
            </section>
            <section className="chatbot-info-card">
              <h3 className="chatbot-info-card__title">About the Technology</h3>
              <p className="chatbot-info-card__details">
                The underlying technology draws on patterns revealed in the data
                of cultural heritage collections. Using semantic connections
                between records, persons, places and other entities, the chatbot
                can offer various engaging scenarios to interact with the data.
                Additionally, the chatbot can be trained on sample conversations
                to better recognise user needs (intents) and generate realistic
                natural language replies.
              </p>
              <img
                src="/assets/img/co-financed-logo.png"
                alt="Co-financed by the Connecting Europe Facility of the European Union"
              />
            </section>
          </div>
        </div>
      </main>
      <footer className="footer">
        <div className="partners">
          <div className="partners-box">
            <div className="partners__item">
              <div className="partners-box__title">Project Partners</div>
            </div>
            {partners.map((p, idx) => (
              <div className="partners__item" key={idx}>
                <a
                  href={p.href}
                  title={p.title}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={p.src} alt={p.title} />
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className="copy-box">
          &copy; Culturebot is the project of Jewish Heritage Network.{' '}
          <a href="mailto:info@jhn.ngo">Contact Us</a>
        </div>
      </footer>
    </div>
  )
}

export default App
