import { send } from 'rasa-webchat'
import React, { useEffect, useState } from 'react'
import './LangSwitcher.scss'

const langs = ['en', 'nl', 'it', 'es', 'pl', 'el']

const LangSwitcher = () => {
  const [lang, setLang] = useState()

  useEffect(() => {
    if (!localStorage.getItem('chat_session')) {
      localStorage.setItem('chat_lang', langs[0])
    }
    setLang(localStorage.getItem('chat_lang'))
  }, [])

  const setLanguage = (newLang) => {
    if (lang !== newLang) {
      localStorage.setItem('chat_lang', newLang)
      setLang(newLang)
      send(`/set_language{"language" : "${newLang}"}`)
    }
  }

  return (
    <div className="lang-switcher">
      {langs.map((l) => {
        const classes = ['lang-switcher__btn']

        if (lang === l) {
          classes.push('lang-switcher__btn_active')
        }

        return (
          <button
            className={classes.join(' ')}
            onClick={() => setLanguage(l)}
            key={l}
          >
            {l.toUpperCase()}
          </button>
        )
      })}
      <button
        className="lang-switcher__btn lang-switcher__btn_warn"
        onClick={() => send('/restart')}
        title="Restart"
      >
        <i className="fas fa-sync"></i>
      </button>
    </div>
  )
}

export default LangSwitcher
